import React, { useEffect, useState, useRef } from 'react';
import { arrayOf, bool, node, number, string, shape, func } from 'prop-types';
import classNames from 'classnames';
import ActionModal from '../action-modal';
import ContainerInfo from './components/container-info';
import SellerStore from './components/seller-store';
import StyledLabel from '../styled-label';
import componentEnhance from '../../lib/component-enhance';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp-seller';
const OFFSET_TOP = {
  OFFICIAL_STORE: 40,
  NO_OFFICIAL_STORE: 32,
};
const SellerInfo = ({
  className,
  seller,
  sellerInfo,
  subtitles,
  title,
  titleValue,
  url,
  showSellerLogo,
  seller_link,
  brandTitle,
  runCatchErrorBoundary,
  force_auto_scroll,
}) => {
  try {
    const { officialStoreId } = seller;
    /* eslint-disable react-hooks/rules-of-hooks */
    const [scrolledComponentExists, setScrolledComponentExists] = useState(false);
    const elementRef = useRef(null);
    const isSellerDataInView = () => {
      const element =
        document.querySelector(`.ui-seller-data`) || document.querySelector(`#seller_info .ui-box-component`);
      elementRef.current = element;
      if (element) {
        setScrolledComponentExists(true);
      }
    };

    useEffect(() => {
      isSellerDataInView();
    });

    const scrollToSellerData = e => {
      e.preventDefault();
      if (!elementRef.current) {
        return;
      }
      isSellerDataInView();
      const element = elementRef?.current;
      if (element) {
        const elementStyle = getComputedStyle(element);
        const elementPaddingTop = parseFloat(elementStyle?.paddingTop);
        const paddingTopSeller = officialStoreId ? OFFSET_TOP.OFFICIAL_STORE : OFFSET_TOP.NO_OFFICIAL_STORE;
        const elementWidgetCompat = document.querySelector(
          '.ui-pdp-compats__compats.compats-block-sticky.compats-block-sticky-active',
        );
        let widgetHeight = 0;
        if (elementWidgetCompat) {
          const elementRect = elementWidgetCompat?.getBoundingClientRect();
          widgetHeight = elementRect?.height - elementPaddingTop + paddingTopSeller;
        }
        const elementRect = element?.getBoundingClientRect();
        const elementPositionScroll = elementRect?.top + elementPaddingTop;
        const targetTop = document.documentElement.scrollTop + elementPositionScroll - widgetHeight - paddingTopSeller;
        window.scrollTo({
          top: Math.max(targetTop, 0),
        });
      }
    };
    const shouldScrollToComponent = force_auto_scroll && scrolledComponentExists;

    return (
      <div className={classNames(namespace, className, { 'ui-pdp-seller__with-logo': !!showSellerLogo })}>
        <SellerStore
          titleValue={
            <>
              {brandTitle && (
                <div className={`${namespace}__brand-title-container`}>
                  <StyledLabel
                    className={`${namespace}__brand-title mb-4`}
                    as="span"
                    font_family={brandTitle.font_family}
                    font_size={brandTitle.font_size}
                    color={brandTitle.color}
                    text={componentEnhance.jsx(brandTitle.text || brandTitle, brandTitle.values)}
                  />
                  {(seller.icon || sellerInfo.official_store_icon) &&
                    IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
                </div>
              )}
              {title && <span className={`${namespace}__label-sold`}>{title}</span>}
              {shouldScrollToComponent ? (
                <StyledLabel data-testid="target_scroll" as="p" {...seller_link.label} onClick={scrollToSellerData} />
              ) : (
                <ActionModal
                  data-testid="action-modal"
                  className={`${namespace}__link-trigger non-selectable`}
                  modalClassName="ui-pdp-modal-seller"
                  deviceType="mobile"
                  componentAsLabel={<StyledLabel as="span" {...seller_link.label} />}
                  modalTitle={seller_link.label.text}
                  modalUrl="#seller-info"
                  url={url}
                  track={seller_link.track}
                  closeModalLabel={seller_link.close_modal_label}
                >
                  <ContainerInfo
                    {...sellerInfo}
                    {...{
                      officialStoreId: seller.officialStoreId,
                      titleValue,
                      brandTitle: sellerInfo.titleLabel,
                      icon: seller.icon || sellerInfo.official_store_icon,
                    }}
                  />
                </ActionModal>
              )}
            </>
          }
          logo={showSellerLogo ? sellerInfo.logo : ''}
          subtitles={subtitles}
          sellerName={titleValue}
          icon={!brandTitle && (seller.icon || sellerInfo.official_store_icon)}
          iconOnTitle
        />
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

SellerInfo.propTypes = {
  className: string,
  seller: shape({
    officialStoreId: number,
  }).isRequired,
  sellerInfo: shape({
    extraInfo: arrayOf(
      shape({
        subtitle: string,
        title: string,
      }),
    ),
    powerSellerStatus: shape({ subtitle: string, title: string }),
    thermometer: shape({
      rank: number,
      info: arrayOf(
        shape({
          icon: shape({ id: string }),
          subtitle: string,
          title: string,
        }),
      ),
      defaultInfo: shape({
        subtitle: shape({
          color: string,
          text: string,
        }),
        title: shape({
          color: string,
          text: string,
        }),
      }),
    }).isRequired,
    subtitle: string,
    logo: string,
    header: string,
  }).isRequired,
  showSellerLogo: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      weight: string,
      size: string,
    }),
  ),
  force_auto_scroll: bool,
  seller_link: shape({
    label: shape({
      text: string,
      color: string,
    }).isRequired,
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }),
    action_type: string,
    target: string,
  }).isRequired,
  title: string,
  titleValue: node.isRequired,
  url: string,
  brandTitle: shape({
    font_family: string,
    color: string,
    font_size: string,
    text: string,
  }),
  runCatchErrorBoundary: func,
};

SellerInfo.defaultProps = {
  className: null,
  showSellerLogo: false,
  subtitles: null,
  title: null,
  url: null,
  brandTitle: null,
  force_auto_scroll: false,
  runCatchErrorBoundary: () => {},
};

export default SellerInfo;
